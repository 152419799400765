<template>
    <a-statistic
        v-if="to - from !== 0"
        :value="
            percentChange(from,to) === Infinity
                ? '>1000' :
                    percentChange(from,to)
        "
        :precision="2"
        suffix="%"
        :value-style="{ color: to - from >= 0 ? '#3f8600' : '#cf1322' }"
        style="margin-right: 50px">
        <template #prefix>
            <a-icon
                :type="
                    to - from > 0
                        ? 'arrow-up' : 'arrow-down'" />
        </template>
    </a-statistic>
</template>
<script>
import { percentChange } from '@/utils/utils'

export default {
    props: {
        from: {
            type: Number,
            required: true,
        },
        to: {
            type: Number,
            required: true,
        },
    },
    data () {
        return {
            percentChange,
        }
    },
}
</script>
