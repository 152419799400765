var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.to - _vm.from !== 0
    ? _c("a-statistic", {
        staticStyle: { "margin-right": "50px" },
        attrs: {
          value:
            _vm.percentChange(_vm.from, _vm.to) === Infinity
              ? ">1000"
              : _vm.percentChange(_vm.from, _vm.to),
          precision: 2,
          suffix: "%",
          "value-style": {
            color: _vm.to - _vm.from >= 0 ? "#3f8600" : "#cf1322"
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "prefix",
              fn: function() {
                return [
                  _c("a-icon", {
                    attrs: {
                      type: _vm.to - _vm.from > 0 ? "arrow-up" : "arrow-down"
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          612869405
        )
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }