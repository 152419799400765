<template>
    <a-card :title="cardTitle">
        <div v-if="report && lastReport">
            <div v-if="!enableFinancialMode">
                <a-statistic
                    title="จำนวนลูกค้าที่มาจากแอพ"
                    :value="report.numberOfCustomer"
                    style="margin-right: 50px">
                    <template #suffix>
                        <VStatisticPercentChange
                            :from="lastReport.numberOfCustomer"
                            :to="report.numberOfCustomer" />
                    </template>
                </a-statistic>
                <a-statistic
                    title="จำนวนโปรโมชั่นที่ขายได้"
                    :value="report.numberOfSoldProduct">
                    <template #suffix>
                        <VStatisticPercentChange
                            :from="lastReport.numberOfSoldProduct"
                            :to="report.numberOfSoldProduct" />
                    </template>
                </a-statistic>
            </div>
            <a-statistic
                title="ยอดขายทั้งหมด"
                :precision="2"
                :value="report.total">
                <template #suffix>
                    <VStatisticPercentChange
                        :from="lastReport.total"
                        :to="report.total" />
                </template>
            </a-statistic>
            <div v-if="enableFinancialMode">
                <a-statistic
                    title="ค่าธรรมเนียม"
                    :value="report.paymentFee" />
                <a-statistic
                    title="รายได้หลังหักค่าธรรมเนียม"
                    :precision="2"
                    :value="report.netTotal">
                    <template #suffix>
                        <VStatisticPercentChange
                            :from="lastReport.netTotal"
                            :to="report.netTotal" />
                    </template>
                </a-statistic>
            </div>
        </div>
        <a-skeleton v-else active />
    </a-card>
</template>
<script>
import { Getters, rootActions } from '@/store/types'
import { percentChange } from '@/utils/utils'
import VStatisticPercentChange from '@/components/VStatisticPercentChange.vue'

export default {
    components: {
        VStatisticPercentChange,
    },
    props: {
        start: {
            type: Object,
            required: true,
        },
        end: {
            type: Object,
            required: true,
        },
        startOfLastReport: {
            type: Object,
            required: true,
        },
        endOfLastReport: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        enableFinancialMode: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            percentChange,
        }
    },
    computed: {
        report () {
            return this.$store.getters[Getters.stadium.GET_REPORT](this.start, this.end)
        },
        lastReport () {
            return this.$store.getters[
                Getters.stadium.GET_REPORT
            ](this.startOfLastReport, this.endOfLastReport)
        },
        cardTitle () {
            const startDateText = this.$options.filters.prettyDate(this.start)
            const endDateText = this.$options.filters.prettyDate(this.end)
            const dateText = startDateText === endDateText
                ? startDateText : `${startDateText } - ${endDateText}`
            return this.title ? `${this.title } : ${dateText}` : dateText
        },
    },
    watch: {
        start () {
            this.fetchData()
        },
        end () {
            this.fetchData()
        },
    },
    async created () {
        this.fetchData()
    },
    methods: {
        fetchData () {
            this.$store.dispatch(
                rootActions.LOAD_STADIUM_REPORT, { start: this.start, end: this.end },
            )
            this.$store.dispatch(
                rootActions.LOAD_STADIUM_REPORT,
                { start: this.startOfLastReport, end: this.endOfLastReport },
            )
        },
    },
}
</script>
