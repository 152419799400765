var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.cardTitle } },
    [
      _vm.report && _vm.lastReport
        ? _c(
            "div",
            [
              !_vm.enableFinancialMode
                ? _c(
                    "div",
                    [
                      _c("a-statistic", {
                        staticStyle: { "margin-right": "50px" },
                        attrs: {
                          title: "จำนวนลูกค้าที่มาจากแอพ",
                          value: _vm.report.numberOfCustomer
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "suffix",
                              fn: function() {
                                return [
                                  _c("VStatisticPercentChange", {
                                    attrs: {
                                      from: _vm.lastReport.numberOfCustomer,
                                      to: _vm.report.numberOfCustomer
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          444238175
                        )
                      }),
                      _c("a-statistic", {
                        attrs: {
                          title: "จำนวนโปรโมชั่นที่ขายได้",
                          value: _vm.report.numberOfSoldProduct
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "suffix",
                              fn: function() {
                                return [
                                  _c("VStatisticPercentChange", {
                                    attrs: {
                                      from: _vm.lastReport.numberOfSoldProduct,
                                      to: _vm.report.numberOfSoldProduct
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3864172223
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("a-statistic", {
                attrs: {
                  title: "ยอดขายทั้งหมด",
                  precision: 2,
                  value: _vm.report.total
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "suffix",
                      fn: function() {
                        return [
                          _c("VStatisticPercentChange", {
                            attrs: {
                              from: _vm.lastReport.total,
                              to: _vm.report.total
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2806909119
                )
              }),
              _vm.enableFinancialMode
                ? _c(
                    "div",
                    [
                      _c("a-statistic", {
                        attrs: {
                          title: "ค่าธรรมเนียม",
                          value: _vm.report.paymentFee
                        }
                      }),
                      _c("a-statistic", {
                        attrs: {
                          title: "รายได้หลังหักค่าธรรมเนียม",
                          precision: 2,
                          value: _vm.report.netTotal
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "suffix",
                              fn: function() {
                                return [
                                  _c("VStatisticPercentChange", {
                                    attrs: {
                                      from: _vm.lastReport.netTotal,
                                      to: _vm.report.netTotal
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1086648767
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _c("a-skeleton", { attrs: { active: "" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }